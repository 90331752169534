/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'usb-plug': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 .5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v4H6zM7 1v1h1V1zm2 0v1h1V1zM6 5a1 1 0 00-1 1v4.394c0 .494.146.976.42 1.387l1.038 1.558c.354.53.542 1.152.542 1.789 0 .481.39.872.872.872h1.256c.481 0 .872-.39.872-.872 0-.637.188-1.26.541-1.789l1.04-1.558A2.5 2.5 0 0012 10.394V6a1 1 0 00-1-1zm0 1h5v4.394a1.5 1.5 0 01-.252.832L9.71 12.784A4.2 4.2 0 009.002 15H7.998a4.2 4.2 0 00-.707-2.216l-1.04-1.558A1.5 1.5 0 016 10.394z"/>',
    },
});
